<template>
  <div class="pa-8 ">
    <page-card
        cardTitle="Back Cover"
        cardTitleIcon="mdi-book-open-page-variant"
    >

      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submit"
          class="pa-4"
          :disabled="readOnlyMode"
      >

        <image-upload
            ref="imageUpload"
            sourceType="page"
            :sourceId=pageName
            fileInputLabel="Choose back cover image"
        />

        <div class="mt-8">
          <v-btn
              :disabled="!valid || readOnlyMode"
              type="submit"
              color="green"
              class="mr-4"
          >
            Save
          </v-btn>
          <v-btn :disabled="readOnlyMode" @click="clear" class="mr-4">Clear</v-btn>
          <v-btn :disabled="!valid" @click="preview">Preview</v-btn>
        </div>

      </v-form>

    </page-card>
  </div>
</template>


<script>
import ImageUpload from "@/components/ImageUpload";
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";

export default {
  name: "Back",
  components: {
    ImageUpload,
    PageCard
  },
  mixins: [form],
  data() {
    return {
      // Have totally made up this pageName as it's for internal use only.
      // The Pureprint API isn't expecting this page, nor will it receive it.
      pageName: "backcover",
      valid: true,
    }
  },
}
</script>
